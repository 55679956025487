<template>
   <el-header class="header-brand">
     <!-- 使用 <img> 标签来引入您的 logo -->
    <img src="@/assets/youthwill-logo.png" alt="YouthWill" />
    </el-header>
  <el-container>

  <el-main>
    <el-row :gutter="10">
  <el-col :xs="24" :sm="12" :md="12" :lg="12">
  <div>
      <!--<h2>烘豆曲线</h2>-->
      <h3>烘豆计划曲线</h3>
      <br>
      <br>
      <canvas ref="smoothLineChart"></canvas>
  </div>
  </el-col>

  <el-col :xs="24" :sm="12" :md="12" :lg="12">
  <div>
    <h3>选择及输入参数</h3>
    <div>
      <label for="substanceContent">物质含量:</label>
      <el-select id="substanceContent" name="substanceContent" v-model="selectedSubstanceContent">
        <el-option value="">请选择</el-option>
        <el-option label="H" value="200"></el-option>
        <el-option label="M-H" value="190"></el-option>
        <el-option label="M" value="180"></el-option>
        <el-option label="M-L" value="165"></el-option>
        <el-option label="L" value="150"></el-option>
      </el-select>
    </div>
    <div>
      <label for="expansionRate">膨胀度:</label>
      <el-select id="expansionRate" name="expansionRate" v-model="selectedExpansionRate">
        <el-option value="">请选择</el-option>
        <el-option label="加速" value="55"></el-option>
        <el-option label="平稳" value="60"></el-option>
        <el-option label="减速" value="65"></el-option>
      </el-select>
    </div>
    <div>
      <label for="colorLevel">上色程度:</label>
      <el-select id="colorLevel" name="colorLevel" v-model="selectedColorLevel">
        <el-option value="">请选择上色程度</el-option>
        <el-option label="偏深" value="10"></el-option>
        <el-option label="中等偏深" value="8"></el-option>
        <el-option label="中等" value="7"></el-option>
        <el-option label="中等偏浅" value="6"></el-option>
        <el-option label="极浅" value="5"></el-option>
      </el-select>
    </div>
    <div>
      <label for="flavorLevel">风味层次:</label>
      <el-select id="flavorLevel" name="flavorLevel" v-model="selectedFlavorLevel">
        <el-option value="">请选择风味层次</el-option>
        <el-option label="内外色差偏大" value="11%"></el-option>
        <el-option label="内外色差略大" value="13%"></el-option>
        <el-option label="内外色差均衡" value="15%"></el-option>
        <el-option label="内外色差略小" value="16%"></el-option>
        <el-option label="内外色差接近" value="18%"></el-option>
      </el-select>
    </div>
    <div>
      <label for="moistureContent">含水量:</label>
      <el-select id="moistureContent" name="moistureContent" v-model="selectedMoistureContent">
        <el-option value="">请选择</el-option>
        <el-option label="8~9%" value="185.00"></el-option>
        <el-option label="9%~10%" value="210.00"></el-option>
        <el-option label="10%~10.5%" value="220.00"></el-option>
        <el-option label="10.5%~11%" value="230.00"></el-option>
        <el-option label="11.0%~12%" value="240.00"></el-option>
        <el-option label="12.0%~12.5%" value="250.00"></el-option>
        <el-option label=">12.5%" value="270.00"></el-option>
      </el-select>
    </div>
    <div>
      <label for="beanTemp">入豆室温（输入温度）:</label>
      <el-input type="number" id="beanTemp" v-model.number="beanTemperature" />
    </div>

    <!-- 一爆时间输入 -->
    <div>
      <label for="firstCrackTemperature">一爆温度:</label>
      <el-input type="number" id="firstCrackTemperature" v-model.number="firstCrackTemperature" />
    </div>
  </div>
  </el-col>

  <el-col :xs="24" :sm="12" :md="12" :lg="12">
  <div>
      <h3>烘焙节点数据</h3>
    <table>
      <thead>
        <tr>
          <th>烘焙关系</th>
          <th>节点秒数</th>
          <th>节点算法</th>
          <th>豆温</th>
          <th>数据对应计算说明</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>入豆</td>
          <td>0</td>
          <td>零点</td>
          <td>{{ beanTemperature }}</td>
          <td>入豆(温度)</td>
        </tr>
        <tr>
          <td>干燥期</td>
          <td>{{ dryTime }}</td>
          <td>干燥期时长</td>
          <td> {{ yellowPoint }}</td>
          <td>黄点（温度）</td>
        </tr>
        <tr>
          <td>美拉德</td>
          <td> {{ afterMaillardMoment}}</td>
          <td>美拉德时长+ 干燥期时长 </td>
          <td> {{ expansionPoint }} </td>
          <td> 膨胀点（温度）</td>
        </tr>
        <tr>
          <td>皮革后</td>
          <td> {{ afterLeatherStateMoment}}</td>
          <td>美拉德时长+ 干燥期时长 +皮革态时间</td>
          <td>{{ firstCrackTemperature }}</td>
          <td>一爆温度</td>
        </tr>
        <tr>
          <td>发展结束</td>
          <td>{{ dropMoment }}</td>
          <td>美拉德时长+ 干燥期时长+皮革态时间+发展期时间</td>
          <td>{{dropTemperature}}</td>
          <td>出豆(温度)</td>
        </tr>
        <!-- 更多的行和单元格 -->
      </tbody>
    </table>
  </div>
  </el-col>

  <el-col :xs="24" :sm="12" :md="12" :lg="12">
  <div>
  <h3>关键数据</h3>
  <table>
      <thead>
        <tr>
          <th>字段</th>
          <th>数值</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>美拉德平均ROR</td>
          <td>{{maillardAverageROR}}</td>
        </tr>
        <tr>
          <td>皮革期平均ROR</td>
          <td>{{leatherStateAverageROR}}</td>
        </tr>
        <tr>
          <td>发展期平均ROR</td>
          <td>{{developmentAverageROR}}</td>
        </tr>
        <tr>
          <td>美拉德时长</td>
          <td>{{ maillardReactionTime }}</td>
          
        </tr>
        <tr>
          <td>皮革态时长</td>
          <td>{{ leatherStateTime }}</td>
        
        </tr>
        <tr>
          <td>发展期时长</td>
          <td>{{ developmentTime }}</td>
         
        </tr>
        <tr>
          <td>发展率（百分比）</td>
          <td>{{ developmentRate }}</td>
  
        </tr>
        <tr>
          <td>升温量(温度) </td>
          <td> {{ increasedTemperature }}</td>
        </tr>
        <tr>
          <td>黄点（温度）</td>
          <td>{{ yellowPoint }}</td>
        </tr>
        <tr>
          <td>膨胀点（温度）</td>
          <td>{{ expansionPoint }}</td>
        </tr>
        <tr>
          <td>出豆（温度）</td>
          <td>{{ dropTemperature }}</td>
        </tr>
        
        <!-- 更多的行和单元格 -->
      </tbody>
    </table>
  </div>
  </el-col>
</el-row>
</el-main>
</el-container>

<el-footer class="footer">
    <div class="footer-text">Theory by Caffrey & Developed by Steven</div>
    <div class="footer-logo">
      <img src="@/assets/youthwill-coffee-logo.png" alt="Youthwill Logo">
    </div>
  </el-footer>

</template>

<script >
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip,ChartDataLabels);

export default {
  name: 'SmoothLineChart',
  mounted() {
    this.renderChart();
  },
  data() {
    return {
      selectedSubstanceContent: '',
      selectedExpansionRate: '',
      selectedColorLevel: '',
      selectedFlavorLevel: '',
      selectedMoistureContent: '',
      firstCrackTemperature:'',
      Chart:null,
      beanTemperature:''
    };
  },

  computed: {
    // 计算后字段的计算属性
    dryTime() {
      // 计算干燥时间的逻辑
      return this.selectedMoistureContent ;
    },
    moistureContent() {
      // 根据干燥时间计算含水量的逻辑
      return '计算结果';
    },
    maillardReactionTime() {
      // 计算美拉德反应时间的逻辑
      return this.selectedSubstanceContent ;
    },
    substanceContent() {
      // 根据美拉德反应时间计算物质含量的逻辑
      return '计算结果';
    },
    leatherStateTime() {
      // 计算皮革态时间的逻辑
      return this.selectedExpansionRate;
    },
    expansionDegree() {
      // 根据皮革态时间计算膨胀度的逻辑
      return '计算结果';
    },
    developmentRate() {
      // 计算发展率的逻辑
      return this.selectedFlavorLevel;
    },
    increasedTemperature() {
      // 计算发展率的逻辑
      return this.selectedColorLevel ;
    },
    yellowPoint() {
      // 计算发展率的逻辑
      const firstCrackTimeNumeric = parseFloat(this.firstCrackTemperature);
      if (isNaN(firstCrackTimeNumeric)) {
        return ''; // 或者返回null或其他默认值
      }
      return this.firstCrackTemperature - 40;
    },
    expansionPoint() {
      // 计算发展率的逻辑
      const firstCrackTimeNumeric = parseFloat(this.firstCrackTemperature);
      if (isNaN(firstCrackTimeNumeric)) {
        return ''; // 或者返回null或其他默认值
      }
      return firstCrackTimeNumeric - 10;
    },
    dropTemperature() {
      // 假设 this.firstCrackTime 和 this.increasedTemperature 最初是字符串类型
        // 使用 Number() 函数将它们转换为数字
        const firstCrackTimeNumeric = Number(this.firstCrackTemperature);
        const increasedTemperatureNumeric = Number(this.increasedTemperature);

        // 检查转换后的值是否为有效数字
        if (!isNaN(firstCrackTimeNumeric) && !isNaN(increasedTemperatureNumeric)) {
        // 如果两个值都是数字，则执行加法运算
        return firstCrackTimeNumeric + increasedTemperatureNumeric;
        } else {
        // 如果有一个不是数字，则返回错误或者其他默认值
        return 'Invalid input'; // 或者您可以选择返回 NaN 或者 null
    
    }   
    },
    developmentTime() {
      let developmentRateNumeric = parseFloat(this.developmentRate) / 100;
      let result = (Number(this.dryTime) + Number(this.maillardReactionTime) +Number(this.leatherStateTime))/(1 / developmentRateNumeric - 1);
      return !isNaN(result) ? Number(result.toFixed(2)) : '';
    },
    // 计算坐标用数据
    // 第三阶段-美拉德后
    afterMaillardMoment(){
     return Number(this.dryTime) + Number(this.maillardReactionTime)
    },
    // 第四阶段-皮革后
    afterLeatherStateMoment(){
     return Number(this.dryTime) + Number(this.maillardReactionTime)+Number(this.leatherStateTime)
    },
    // 第五阶段-结束发展
    dropMoment(){
     let result = Number(this.dryTime) + Number(this.maillardReactionTime)+Number(this.leatherStateTime)+Number(this.developmentTime);
     return result.toFixed(2);
    },
    //计算ROR
    maillardAverageROR(){
     let result = (Number(this.expansionPoint) - Number(this.yellowPoint))/Number(this.maillardReactionTime)*60;
     return isNaN(result) ? '' : result;
    },
    //计算ROR
    leatherStateAverageROR(){
      let result =  (Number(this.firstCrackTemperature) - Number(this.expansionPoint))/Number(this.leatherStateTime)*60;
      return isNaN(result) ? '' : result;
    },
    developmentAverageROR(){
      let result = (Number(this.dropTemperature) - Number(this.firstCrackTemperature))/Number(this.developmentTime)*60;
      return isNaN(result) ? '' : result;
    },
  },
  watch: {

    //坐标1：y点
    beanTemperature(newValue) {
      if (this.chart) {
        // 当选中的含水量改变时，更新图表的数据点
        this.chart.data.datasets[0].data[0].y = newValue ? parseFloat(newValue) : 25.0;
        this.chart.update(); // 更新图表来反映新的数据
      }
    },
    //坐标2：x点
    dryTime(newValue) {
      if (this.chart) {
        // 当选中的含水量改变时，更新图表的数据点
        this.chart.data.datasets[0].data[1].x = newValue ? parseFloat(newValue) : 25.0;
        this.chart.update(); // 更新图表来反映新的数据
      }
    },
     //坐标2：y点
    yellowPoint(newValue) {
      if (this.chart) {
        // 当选中的含水量改变时，更新图表的数据点
        this.chart.data.datasets[0].data[1].y = newValue ? parseFloat(newValue) : 25.0;
        this.chart.update(); // 更新图表来反映新的数据
      }
    },
     //坐标3：x点
     afterMaillardMoment(newValue) {
      if (this.chart) {
        // 当选中的含水量改变时，更新图表的数据点
        this.chart.data.datasets[0].data[2].x = newValue ? parseFloat(newValue) : 25.0;
        this.chart.update(); // 更新图表来反映新的数据
      }
    },
    //坐标3：y点
    expansionPoint(newValue) {
      if (this.chart) {
        // 当选中的含水量改变时，更新图表的数据点
        this.chart.data.datasets[0].data[2].y = newValue ? parseFloat(newValue) : 25.0;
        this.chart.update(); // 更新图表来反映新的数据
      }
    },
    //坐标4：x点
    afterLeatherStateMoment(newValue) {
      if (this.chart) {
        // 当选中的含水量改变时，更新图表的数据点
        this.chart.data.datasets[0].data[3].x = newValue ? parseFloat(newValue) : 25.0;
        this.chart.update(); // 更新图表来反映新的数据
      }
    },
    //坐标4：y点
    firstCrackTemperature(newValue) {
      if (this.chart) {
        // 当选中的含水量改变时，更新图表的数据点
        this.chart.data.datasets[0].data[3].y = newValue ? parseFloat(newValue) : 25.0;
        this.chart.update(); // 更新图表来反映新的数据
      }
    },
    //坐标5：x点
    dropMoment(newValue) {
      if (this.chart) {
        // 当选中的含水量改变时，更新图表的数据点
        this.chart.data.datasets[0].data[4].x = newValue ? parseFloat(newValue) : 25.0;
        this.chart.update(); // 更新图表来反映新的数据
      }
    },
    //坐标5：y点
    dropTemperature(newValue) {
      if (this.chart) {
        // 当选中的含水量改变时，更新图表的数据点
        this.chart.data.datasets[0].data[4].y = newValue ? parseFloat(newValue) : 25.0;
        this.chart.update(); // 更新图表来反映新的数据
      }
    },
  },
  methods: {
    renderChart() {
      const ctx = this.$refs.smoothLineChart.getContext('2d');
      // 如果之前已经有图表实例了，先销毁它
      if (this.chart) {
        this.chart.destroy();
      }
      // 创建新的图表实例并存储在组件的数据属性中
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: [
            {
              label: '',
              data: [
                { x: 0, y: 25.0 },
                { x: 220, y: 155.0 },
                { x: 410, y: 172.0 },
                { x: 465, y: 182.0 },
                { x: 554, y: 189.0 }
              ],
              fill: false,
              borderColor: 'rgb(176,105,60)',
              tension: 0.4, // 控制曲线平滑度
              pointBackgroundColor: 'rgb(103,76,57)',
              pointBorderColor: 'rgb(103,76,57)',
              pointRadius: 5,
              pointHoverRadius: 7,
              showLine: true // 显示线条
            }
          ]
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: '烘焙曲线图'
          },
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              title: {
                display: true,
                text: '时间（秒）'
              }
            },
            y: {
              beginAtZero: false,
              title: {
                display: true,
                text: '温度'
              }
            }
          },
          plugins: {
            datalabels: {
            align: 'start',
            anchor: 'start',
            formatter: function(value) {
            return value.x.toFixed(2) +','+value.y.toFixed(2)+'°C';

            },
            color: '#555',
            font: {
           weight: 'regular'
            }
          },
            tooltip: {
              enabled: true,
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y.toFixed(2) + '°C';
                  }
                  return label;
                }
              }
            }
          }
        }
      });
    }
  }
};


</script>


<style>
/* 添加CSS样式以美化表格 */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

thead {
  background-color: #f0f0f0;
}

.header-brand {
  display: flex;
  justify-content: center; /* 品牌名居中显示 */
  align-items: center; /* 垂直居中 */
  color: #AA693C; /* 字体颜色 */
  background-color: #f0f0f0; /* 背景颜色 */
}

.el-header {
  height: 60px; /* 设置头部的高度 */
}

.el-container {
  width: 100%
  /* 默认没有外边距 */
}

.header-brand img {
  height: 40px; /* 举例：设置 logo 的高度 */
  width: auto; /* 保持 logo 的宽高比 */
  /* 添加额外的样式，如边距、对齐方式等 */
}

 /* 修改小标题颜色 */

h3 {
    color: #674C39; /* 您想要的颜色 */
  }


/* Footer 样式 */
.footer {
  text-align: center; /* 文字居中 */
  padding: 10px;
}

.footer-text,
.footer-logo {
  margin-bottom: 10px; /* 为元素的底部添加间距 */
}

.footer-logo img {
  height: 80px; /* 或者您想要的尺寸 */
}

</style>